body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f5f8fa !important;
}

.warn-toast {
  background-color: #e65100;
}

input:invalid {
  border-color: #e65100;
}

input:aria-invalid {
  border-color: #e65100;
}

input:-internal-autofill-selected {
  background: red !important;
  color: red;
  background-color: red !important;
}

/* .app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(/images/logo-bg.png);
  box-sizing: border-box;
  padding: 20px;
  background-origin: content-box;
} */
/* input:-internal-autofill-selected {
  background-color: transparent;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
} */
